<template>
  <div>
    <!-- footer start -->
    <div class="footer d-none d-sm-block">
      <div class="main">
        <div class="main_one">
          <img src="@/assets/image/logo2.png" alt="" />
          <div class="footer_tit">智热服务    让您赢得未来</div>
        </div>
        <div class="footer_main">
          <div class="footer-lin1">
            <div class="footer_min_tit">期待为您服务</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>近十年解决方案经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>专注的领域经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>近50人研发经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>全球大客户服务经验</div>
            </div>
            <div></div>
          </div>
          <div class="footer-lin2">
            <div class="footer_min_tit">联系我们</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer5.png" alt="" />
              </div>
              <div>电话 : (+86)-xxxx-85582181</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer4.png" alt="" />
              </div>
              <div>邮件 : support@i4season.com</div>
            </div>
            <div>
              <div class="mg_bom d-flex">
                <div class="mg_ri">
                  <img src="@/assets/image/footer1.png" alt="" />
                </div>
                <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
              </div>
              <div class="mg_bom d-flex">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广东省深圳市龙华区品客小镇青创城A栋2-B2
              </div>
              <div class="mg_bom d-flex">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长沙市高新技术开发区麓谷企业广场F2栋12楼1208号
              </div>
            </div>
          </div>
          <div class="footer-lin3">
            <div class="footer_min_tit">
              业务经理
              <div class="lin_ft"></div>
            </div>

            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer3.png" alt="" />
              </div>
              <div>钱经理 : 158 9236 8127</div>
            </div>
            <div>
              <img src="@/assets/image/wechat.png" alt="" />
            </div>
          </div>
        </div>
        <div class="footer-ft">
          © 2017-2021 四季宏胜科技有限公司 版权所有 | <a class="href" href="https://beian.miit.gov.cn/">粤ICP备14055127号-2</a>
        </div>
      </div>
    </div>

    <div class="footer d-sm-none">
      <div class="">
        <div class="main_one">
          <img width="40%" src="@/assets/image/logo2.png" alt="" />
          <div class="footer_tit">智热服务    让您赢得未来</div>
        </div>
        <div class="footer-lin3 d-flex">
          <div>
            <div class="footer_min_tit add_footer_min_tit">业务经理</div>

            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer3.png" alt="" />
              </div>
              <div>钱经理 : 158 9236 8127</div>
            </div>
          </div>
          <div>
            <img src="@/assets/image/wechat.png" alt="" />
          </div>
        </div>
        <div class="">
          <div class="footer-lin1">
            <div class="footer_min_tit">期待为您服务</div>
            <div class="lin_ft"></div>
            <div class="d-flex">
              <div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/footer2.png" alt="" />
                  </div>
                  <div>近十年解决方案经验</div>
                </div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/footer2.png" alt="" />
                  </div>
                  <div>专注的领域经验</div>
                </div>
              </div>
              <div style="margin-left: 10px">
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/footer2.png" alt="" />
                  </div>
                  <div>近50人研发经验</div>
                </div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/footer2.png" alt="" />
                  </div>
                  <div>全球大客户服务经验</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div class="footer-lin2">
            <div class="footer_min_tit">联系我们</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer5.png" alt="" />
              </div>
              <div>电话 : (+86)-xxxx-85582181</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer4.png" alt="" />
              </div>
              <div>邮件 : support@i4season.com</div>
            </div>
            <div>
              <div class="mg_bom d-flex">
                <div class="mg_ri">
                  <img src="@/assets/image/footer1.png" alt="" />
                </div>
                <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
              </div>
              <div class="mg_bom d-flex">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广东省深圳市龙华区品客小镇青创城A栋2-B2
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;长沙市高新技术开发区麓谷企业广场F2栋12楼1208号
              </div>
            </div>
          </div>
        </div>
        <div class="footer-ft">
          <div class="cen">© 2017-2021 四季宏胜科技有限公司</div>
          <a href="https://beian.miit.gov.cn/" class="cen">版权所有 | 粤ICP备14055127号-2</a>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>
// footer start
.href{
  color: #fff;
  text-decoration: none;
}
.footer {
  // background: #4066f5;
  // background: url("../assets/image/her1.png") no-repeat center;
  background: #4066f5;
  color: #fff;
  font-family: PingFangSC-Medium;
  padding-bottom: 24px;
}
.main_one {
  height: 126px;
  display: flex;
  align-items: center;
  img{
    width: 92px;
    height: 26px;
  }
}
.footer_main {
  display: flex;
  justify-content: space-between;
}
.footer_tit {
  color: #fff;
  font-weight: 200;
  font-family: FZLTXHK--GBK1-0;
  letter-spacing: 2px;
  font-size: 24px;
  margin-left: 15px;
}
.footer_min_tit {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  // border-bottom:2px solid #fff ;
}
.lin_ft {
  width: 80px;
  border-bottom: 4px solid #fff;
  margin: 24px 0;
}
.mg_bom {
  margin-bottom: 12px;
}
.footer-ft {
  text-align: center;
  margin-top: 10px;
}
.mg_ri {
  margin-right: 8px;
  margin-top: -2px;
}
// footer end
// 手机
@media only screen and (max-width: 760px) {
//footer
    .footer {
        width: calc(100vw);
        padding: 0 20px;
        background: #4066f5;
    }
    .footer-lin3 {
        justify-content: space-between;
    }
    .footer-lin1 {
        margin-bottom: 20px;
        font-size: 12px;
    }
    .footer-lin2 {
        font-size: 12px;
    }
    .footer-lin3 {
        margin-bottom: 20px;
        font-size: 12px;
    }
    .lin_ft {
        border-bottom: 2px solid #fff;
        margin: 10px 0;
    }
    .footer_min_tit {
        font-size: 16px;
    }
    .footer-ft {
      text-align: center;
        padding: 26px 0;
    }
    .cen {
      color: #fff;
      text-decoration: none;
        text-align: center;
        display: inline-block;
    }
    .add_footer_min_tit {
        margin-bottom: 16px;
    }
    .footer-ft {
        font-size: 12px;
    }
    .footer_tit {
        font-size: 20px;
    }
    .banner_add_ph {
    position: absolute;
    z-index: 999;
    left: 5%;
    top: 38%;
    color: #fff;
    }
    .add_ph_tit {
    font-size: 10px;
    transform: scale(0.6);
    transform-origin: 0 0;
    white-space: nowrap;
    margin-top: 9px;
    margin-left: 4px;
    }
    .box_add_ph_tit {
    height: 18px;
    }
}
</style>
