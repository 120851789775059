<template>
    <!-- 产品线start -->
    <div class="productLine">
      <div class="main productLine_min">
        <div class="_tit">产品线</div>
        <div class="productLine_tit2">基于存储通讯协议，丰富不同场景应用</div>
        <div class="_lin"></div>
        <el-tabs v-model="activeName" >
          <el-tab-pane label="消费类智能储存产品" name="index1">
            <template slot="label"
              ><div class="tab_iti">消费类智能<br>储存产品</div></template
            >
            <div class="nav scroller">
              <div class="nav_list">
                <div :class="nav==1?'nav_active':'nav_item'" @click="navclick(1,1)"><a href="#div2">固态硬盘(SSD)智能产品</a></div>
                <div :class="nav==2?'nav_active':'nav_item'" style="width: 220px;" @click="navclick(1,2)"> <a href="#div3">机械硬盘(HDD)智能产品</a></div>
                <div :class="nav==3?'nav_active':'nav_item'" @click="navclick(1,3)"><a href="#div4">U盘智能产品</a></div>
                <div :class="nav==3?'nav_active':'nav_item'" @click="navclick(1,3)"><a href="#div4">存储附件智能产品</a></div>
              </div>
            </div>
            <!-- 1 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">加密固态硬盘</div>
              <div class="productLine_imbox add_productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/cp(2).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul style="display:flex;flex-wrap: wrap;">
                      <li style="width:220px;margin-right: 20px;">全套解决方案;</li>
                      <li style="width:220px">自主研发的硬件与软件;</li>
                      <li style="width:220px;margin-right: 20px;">自主知识产权;</li>
                      <li style="width:220px">支持高速数据读写(>200MB/s);</li>
                      <li style="width:220px;margin-right: 20px;">方案出货量超过90%;</li>
                      <li style="width:220px">支持分区数据加密保护;</li>
                      <li style="width:220px;margin-right: 20px;">支持安卓/电脑二种设备;</li>
                      <li style="width:220px">设备支持Type-C+USB 3.0双接口;</li>
                      <li style="width:220px;margin-right: 20px;">支持Windows/MAC/Android 6.0以上的APP操作;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    <ul style="display:flex;flex-wrap: wrap;">
                      <li style="width:220px;margin-right: 20px;">芯片安卓手机与PC直连访问;</li>
                      <li style="width:220px">支持USB3.0高速传输;</li>
                      <li style="width:220px;margin-right: 20px;">支持SSD存储介质;</li>
                      <li style="width:220px">支持加密盘;</li>
                      <li style="width:220px;margin-right: 20px;">访问加密盘密码认证保护;</li>
                      <li style="width:220px">支持文件管理;</li>
                      <li style="width:220px;margin-right: 20px;">支持一键备份;</li>
                      <li style="width:220px">支持通讯录备份;</li>
                      <li style="width:220px;margin-right: 20px;">手机照片选择备份;</li>
                      <li style="width:220px">兼容多视频格式打开;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="productLine_btn" @click="detailclick(1)">了解详情</div>
            </div>
            <div class="shade" v-if="show"></div>
            <div class="from" v-if="show">
              <div class="con">
                <div class="cha" @click="chaclick(1)">X</div>
                <div class="con-top">
                  <div class="con-img">
                    <img src="@/assets/image/product/cp(2).png" alt="">
                  </div>
                  <div class="con-message">
                    <div style="width: 50px;height: 4px;background: #4066F5;"></div>
                    <div class="name">加密固态硬盘</div>
                    <div class="type">HOOTOO</div>
                    <div class="parameter">产品参数</div>
                    <div class="item"><div></div>产品类型: 加密固态硬盘</div>
                    <div class="item"><div></div>主控: JMS580</div>
                    <div class="item"><div></div>加密支持: ATA Lock</div>
                    <div class="item"><div></div>存储容量: 128GB/256GB/512GB/1TB/2TB</div>
                    <div class="item"><div></div>存储类型: SSD</div>
                    <div class="item"><div></div>接口类型: USB3.0，Type-C</div>
                    <div class="item"><div></div>数据传输速率: 读 450MB/s，写 430MB/s</div>
                    <div class="parameter">功能特点</div>
                    <div class="item"><div></div> 支持高速数据读写(>400MB/s)</div>
                    <div class="item"><div></div>支持分区数据加密保护</div>
                    <div class="item"><div></div>支持Windows/MacOS/Android 6.0以上的APP操作</div>
                    <div class="item"><div></div>支持加密盘密码认证保护</div>
                    <div class="item"><div></div>支持文件管理、一键备份、通讯录备份</div>
                    <div class="item"><div></div>广泛兼容各种文件类型</div>
                  </div>
                </div>
                <div class="con-bot">
                  <div class="bot-left">
                    <div class="parameter">优势</div>
                    <div style="display: flex;flex-wrap: wrap;">
                      <div class="item"><div></div>全套解决方案;</div>
                      <div class="item"><div></div>自主研发的硬件与软件;</div>
                      <div class="item"><div></div>支持高速数据读写(>200MB/s);</div>
                      <div class="item"><div></div>方案出货量超过90%;</div>
                      <div class="item"><div></div>支持分区数据加密保护;</div>
                      <div class="item"><div></div>支持安卓/电脑二种设备;</div>
                      <div class="item"><div></div>设备支持Type-C+USB 3.0双接口;</div>
                      <div class="item"><div></div>支持Windows/MAC/Android 6.0以上的APP操作;</div>
                    </div>
                  </div>
                  <div class="bot-right">
                    <div class="parameter">特点</div>
                    <div style="display: flex;flex-wrap: wrap;">
                      <div class="item"><div></div>芯片安卓手机与PC直连访问;</div>
                      <div class="item"><div></div>支持USB3.0高速传输;</div>
                      <div class="item"><div></div>支持SSD存储介质;</div>
                      <div class="item"><div></div>支持加密盘;</div>
                      <div class="item"><div></div>访问加密盘密码认证保护;</div>
                      <div class="item"><div></div>支持文件管理;</div>
                      <div class="item"><div></div>支持一键备份;</div>
                      <div class="item"><div></div>支持通讯录备份;</div>
                      <div class="item"><div></div>支持通讯录备份;</div>
                      <div class="item"><div></div>兼容多视频格式打开;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 1 -->
            <!-- <div class="productLine_main">
              <div class="productLine_main_tit">wifi硬盘</div>
              <div class="productLine_imbox add_productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/cp(2).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>独家全套解决方案;</li>
                      <li>自主知识产权;</li>
                      <li>方案出货量超过50%;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>支持苹果手机和安卓手机;</li>
                      <li>支持局域网访问和远程访问;</li>
                      <li>手机轻松看硬盘大片;</li>
                      <li>常规文件管理;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="productLine_btn" @click="detailclick(1)">了解详情</div>
            </div>
            <div class="shade" v-if="show"></div>
            <div class="from" v-if="show">
              <div class="con">
                <div class="cha" @click="chaclick(1)">X</div>
                <div class="con-top">
                  <div class="con-img">
                    <img src="@/assets/image/product/cp(2).png" alt="">
                  </div>
                  <div class="con-message">
                    <div style="width: 50px;height: 4px;background: #4066F5;"></div>
                    <div class="name">苹果手机U盘(三合一)</div>
                    <div class="type">HOOTOO</div>
                    <div class="parameter">产品参数</div>
                    <div class="item"><div></div>产品类型: 苹果U盘、三合一</div>
                    <div class="item"><div></div>主控: NS1083  （瑞发科）</div>
                    <div class="item"><div></div>存储容量:  128GB/256GB/512GB/1TB</div>
                    <div class="item"><div></div>存储类型: TF卡</div>
                    <div class="item"><div></div>接口类型: USB3.0，Type-C、Lightning、Micro USB</div>
                    <div class="item"><div></div>数据传输速率: 读取 120MB/s，写入 50MB/s</div>
                    <div class="parameter">功能特点</div>
                    <div class="item"><div></div>产品类型: 苹果U盘、三合一</div>
                    <div class="item"><div></div>主控: NS1083  （瑞发科）</div>
                    <div class="item"><div></div>存储容量:  128GB/256GB/512GB/1TB</div>
                    <div class="item"><div></div>存储类型: TF卡</div>
                    <div class="item"><div></div>接口类型: USB3.0，Type-C、Lightning、Micro USB</div>
                  </div>
                </div>
                <div class="con-bot">
                  <div class="bot-left">
                    <div class="parameter">优势</div>
                    <div style="display: flex;flex-wrap: wrap;">
                      <div class="item"><div></div>产品类型: 苹果U盘、三合一</div>
                      <div class="item"><div></div>主控: NS1083  （瑞发科）</div>
                      <div class="item"><div></div>存储容量:  128GB/256GB/512GB/1TB</div>
                      <div class="item"><div></div>存储类型: TF卡</div>
                      <div class="item"><div></div>接口类型: USB3.0，Type-C、Lightning、Micro USB</div>
                      <div class="item"><div></div>数据传输速率: 读取 120MB/s，写入 50MB/s</div>
                    </div>
                  </div>
                  <div class="bot-right">
                    <div class="parameter">特点</div>
                    <div style="display: flex;flex-wrap: wrap;">
                      <div class="item"><div></div>产品类型: 苹果U盘、三合一</div>
                      <div class="item"><div></div>主控: NS1083  （瑞发科）</div>
                      <div class="item"><div></div>存储容量:  128GB/256GB/512GB/1TB</div>
                      <div class="item"><div></div>存储类型: TF卡</div>
                      <div class="item"><div></div>接口类型: USB3.0，Type-C、Lightning、Micro USB</div>
                      <div class="item"><div></div>数据传输速率: 读取 120MB/s，写入 50MB/s</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- 2 -->
            <div class="productLine_main" id="div2">
              <div class="productLine_main_tit">NAS硬盘</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/cp(1).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>独家全套解决方案;</li>
                      <li>自主知识产权;</li>
                      <li>方案在行业领域有很好占有率，如：图片工作室;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>支持1块硬盘/2块硬盘;</li>
                      <li>支持1G有线;</li>
                      <li>支持5G WiFi;</li>
                      <li>支持局域网访问和远程访问;</li>
                      <li>支持手机备份、文件备份功能;</li>
                      <li>手机轻松看硬盘大片;</li>
                      <li>常规文件管理;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 3 -->
            <div class="productLine_main" id="div3">
              <div class="productLine_main_tit">网络存储连接器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/cp(3).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>独家全套解决方案;</li>
                      <li>自主知识产权;</li>
                      <li>方案出货量超过50;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>自带电池;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 4 -->
            <div class="productLine_main" id="div4">
              <div class="productLine_main_tit">网络存储连接器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/cp(3).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>独家全套解决方案;</li>
                      <li>自主知识产权;</li>
                      <li>方案出货量超过50;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>自带电池;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="行业类加密储存产品" name="index2">
            <template slot="label"
              ><div class="tab_iti">行业类加密<br>储存产品</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机U盘</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (1).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    <ul class="pro_item">
                      <li>独家全套解决方案;</li>
                      <li>自主知识产权;</li>
                      <li>方案出货量超过50%;</li>
                    </ul>
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 2 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机SSD</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (2).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 3 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机硬盘</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (3).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 4-->
            <div class="productLine_main">
              <div class="productLine_main_tit">指纹手机存储</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (4).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 5 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">加密手机存储</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (5).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 6 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机存储连接器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/b (6).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="宠物类智能方案定制" name="index3">
            <template slot="label"
              ><div class="tab_iti">宠物类智能<br>方案定制</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机连接黑头仪</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (2).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 2 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机连接耳道镜</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (3).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 3 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机连接内窥镜</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (4).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 4-->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机链接显微镜</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (5).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 ; 自主知识产权 ; 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机﹔支特局域网访问和远程访问;手机经松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 5 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手机连接洁牙器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (6).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                    冲牙器
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 6 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">网络存储连接器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/a (1).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="iPhone摄像头方案定制" name="index4">
            <template slot="label"
              ><div class="tab_iti">iPhone摄像头<br>方案定制</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能窗帘</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (7).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 2 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能摄像头</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (1).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 3 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能塔扇</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (2).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 4-->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能空净</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (5).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 ;自主知识产权 ; 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 5 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能门铃</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (6).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案;自主知识产权;方案在行业领域有很好占有率，如：图片工作室
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持1块硬盘/2块硬盘;支持1G有线;支持5G
                    WiFi;支持局域网访问和远程访问;支持手机备份、文件备份功能;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 6 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能防丢器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (3).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
            <!-- 7 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">智能投食器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/h (4).png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 / 自主知识产权 / 方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">自带电池</div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="小家电私有云方案定制" name="index5">
            <template slot="label"
              ><div class="tab_iti">小家电私有<br>云方案定制</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
              <div class="productLine_main_tit">手势控制</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/651741ba187fab58835530117216992.png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全套解决方案 ; 自主知识产权 ;方案出货量超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机;支持局域网访问和远程访问;手机轻松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div></el-tab-pane
          >
          <el-tab-pane label="IoT模组方案定制" name="index6">
            <template slot="label"
              ><div class="tab_iti">IoT模组<br>方案定制</div></template
            >
            <div class="productLine_main">
              <div class="productLine_main_tit">存储拓展器</div>
              <div class="productLine_imbox">
                <img
                  class="productLine_imbox-im"
                  src="@/assets/image/product/1ad85d65e144271652b7ccab48b5b16.png"
                  alt=""
                />
              </div>
              <div class="productLine_minbox d-flex">
                <div class="productLine_minbox_left">
                  <div class="pro_tit">优势</div>
                  <div class="pro_p">
                    独家全女解决方窦;自主知识产权;方室出货景超过50%
                  </div>
                </div>
                <div class="productLine_minbox_right">
                  <div class="pro_tit">特点</div>
                  <div class="pro_p">
                    支持苹果手机和安卓手机﹔支特局域网访问和远程访问;手机经松看硬盘大片;常规文件管理
                  </div>
                </div>
              </div>
              <div class="productLine_btn">了解详情</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 产品线end -->
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeName: "index1",
      nav: 1,
      show: false
    }
  },
  methods: {
    // detailclick(e) {
    //   // eslint-disable-next-line eqeqeq
    //   if (e == 1) {
    //     this.show = true
    //   }
    // },
    chaclick(e) {
      // eslint-disable-next-line eqeqeq
      if (e == 1) {
        this.show = false
      }
    },
    navclick(e, d) {
      console.log(e)
      this.nav = d
    }
  }
}
</script>

<style lang="scss" scoped>
ul li::marker{
  color: #4066F5;
}
// 遮罩
.shade{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(#000, 0.5);
  z-index: 55;
}
.from{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  padding: 40px 0px;
  background: #fff;
  z-index: 99;
  .cha{
    text-align: right;
    font-size: 24px;
    margin-right: 100px;
    cursor: pointer;
  }
  .con{
    width: 1200px;
    margin: 0 auto;
    .con-bot{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0px 30px;
      .bot-left{
        width: 50%;
        .parameter{
          width: 99px;
          height: 31px;
          border: 1px solid #D9E1FF;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          color: #4066F5;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .item{
          width: 270px;
          font-size: 12px;
          line-height: 26px;
          color: #676767;
          // display: flex;
          // align-items: center;
          div{
            width: 2px;
            height: 13px;
            background: #4066F5;
            opacity: 1;
            border-radius: 0px;
            margin-right: 5px;
            display: inline-block;
          }
        }
      }
      .bot-right{
        width: 50%;
        .parameter{
          width: 99px;
          height: 31px;
          border: 1px solid #D9E1FF;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          color: #4066F5;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .item{
          width: 270px;
          font-size: 12px;
          line-height: 26px;
          color: #676767;
          // display: flex;
          // align-items: center;
          div{
            width: 2px;
            height: 13px;
            background: #4066F5;
            opacity: 1;
            border-radius: 0px;
            margin-right: 5px;
            display: inline-block;
          }
        }
      }
    }
    .con-top{
      display: flex;
      justify-content: center;
      .con-img{
        width: 600px;
        height: 450px;
        background: #f9faff;
        text-align: center;
        padding-top: 35px;
        img{
          width: 480px;
          height: 380px;
        }
      }
      .con-message{
        margin-left: 50px;
        .name{
          font-size: 26px;
          color: #4066F5;
        }
        .type{
          font-size: 14px;
          color: #202020;
        }
        .parameter{
          width: 99px;
          height: 31px;
          border: 1px solid #D9E1FF;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          color: #4066F5;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .item{
          font-size: 12px;
          line-height: 26px;
          color: #676767;
          display: flex;
          align-items: center;
          div{
            width: 2px;
            height: 13px;
            background: #4066F5;
            opacity: 1;
            border-radius: 0px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

// 标题
._tit {
  margin-bottom: 4px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Regular;
  color: #333333;
  font-weight: 400;
}
._tit2 {
  text-align: center;
  font-family: SFPro-Bold;
  font-size: 24px;
  color: #dddddd;
  font-weight: 700;
  line-height: 30px;
}
._lin {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  display: flex;
  justify-content: center;
  margin: 24px auto;
}
    // 产品线start
    // ::-webkit-scrollbar {
    //   width: 0 !important;
    // }
    // ::-webkit-scrollbar {
    //   width: 0 !important;height: 0;
    // }
.nav{
    padding: 20px 60px;
    width: 1200px;
    overflow-x: scroll;
  .nav_list{
    display: flex;
    align-items: center;
    .nav_item{
      // width: 200px;
      text-align: center;
      font-size: 14px;
      color: #596580;
      padding: 5px 19px;
      margin-right: 40px;
      a{
        color:#000;
        text-decoration: none;
      }
    }
    .nav_active{
      text-decoration: none;
      // width: 200px;
      text-align: center;
      font-size: 14px;
      color: #4066F5;
      padding: 5px 19px;
      background: rgba(#4066F5, 0.1);
      margin-right: 40px;
      a{
        text-decoration: none;
      }
    }
  }
}
.productLine {
  background: #f5f7fe;
  padding-top: 60px;
  padding-bottom: 60px;
}
.productLine_tit2 {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666666;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
}
/deep/.el-tabs__nav-wrap {
  display: flex;
  justify-content: center;
}
/deep/.el-tabs__active-bar {
  background-color: #4066f5;
  margin-bottom: -10px;
}
/deep/.el-tabs__item.is-active {
  color: #4066f5;
}
/deep/.el-tabs__nav{
  margin-bottom: 10px;
}
/deep/.el-tabs__item {
  padding: 0 50px;
}
.tab_iti {
  text-align: center;
  line-height: 20px;
  font-size: 16px !important;
}
.productLine_main {
  position: relative;
  margin-top: 20px;
  padding-bottom: 40px;
  background: #fff;
}
.productLine_main_tit {
  height: 100px;
  background-image: url("../assets/image/product/bg.png");
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #4066f5;
  line-height: 40px;
  font-weight: 600;
  padding: 30px 40px;
}

.productLine_imbox {
  position: absolute;
  top: 10%;
  right: 2%;
}
.productLine_minbox {
  padding: 40px 40px 20px 40px;
}
.productLine_minbox_left {
  width: 44%;
  // margin-right: 10%;
}
.productLine_minbox_right {
  width: 44%;
  // margin-right: 10%;
}
.pro_tit {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #040849;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.pro_p {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #596580;
  line-height: 24px;
  font-weight: 400;
}
.productLine_btn {
  cursor: pointer;
  width: 98px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #4066f5;
  border: 1px solid rgba(64, 102, 245, 1);
  border-radius: 2px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 40px;
}
// 产品线end

// 手机
@media only screen and (max-width: 760px) {
  // 标题
  ._tit {
    font-size: 20px;
  }
  ._tit2 {
    font-size: 14px;
  }
  ._lin {
    border-bottom: 2px solid #4066f5;
  }
  // 产品线
  .nav{
      padding: 40px 60px;
      width: 100vw;
      overflow-x: scroll;
    .nav_list{
      display: flex;
      align-items: center;
      .nav_item{
        width: 200px;
        text-align: center;
        font-size: 14px;
        color: #596580;
        padding: 5px 19px;
        margin-right: 40px;
      }
      .nav_active{
        width: 150px;
        text-align: center;
        font-size: 14px;
        color: #4066F5;
        padding: 5px 19px;
        background: rgba(#4066F5, 0.1);
        margin-right: 40px;
      }
    }
  }
  .productLine_min {
    width: calc(100vw);
  }
  .productLine_imbox-im {
    width: 50%;
  }
  .productLine_tit2 {
    font-size: 14px;
  }
  .pro_tit {
    font-size: 16px;
  }
  .pro_p {
    font-size: 14px;
  }
  .productLine_btn {
    font-size: 14px;
  }
  .productLine_imbox {
    position: absolute;
    top: 4%;
    right: -33%;
  }
  .add_productLine_imbox {
    position: absolute;
    top: 4%;
    right: -25%;
  }
  .productLine_minbox_left {
    width: 40%;
  }
  .productLine_minbox_right {
    width: 70%;
  }
  .productLine_main_tit {
    font-size: 20px;
  }

}
</style>
